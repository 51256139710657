import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useRef } from "react";

export default function InterstitialPage() {
    const navigate = useNavigate();

    const { state } = useLocation();
    const { address } = state;

    let btnRef = useRef();

    const submitForm = () => {

        if (btnRef.current) {
            btnRef.current.setAttribute("disabled", "disabled");
        }

        console.log("Intersitial Submitted");

        axios.post('https://propcma-api.shared.friserver.network/v1/cma/generate', {
            address: address
        })
        .then(function (response) {
            console.log(response);
            navigate("/report", { state: { address: address, data: response.data } });
        })
        .catch(function (error) {
            console.log(error);
        });
    };

    return (
        <div id="landing-page">
            <div className="bg-white py-16 sm:py-24">
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32">
                        <h2 className="mx-auto max-w-2xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl">
                            {address}
                        </h2>
                        <p className="mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300">
                            Receive a free report and estimate in seconds!
                        </p>
                       
                       <div class="grid grid-cols-2 mt-8">

                            <div class="blur-xl">
                                <img src="/report_part.png"/>
                            </div>

                            <div class="align-middle ml-20">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-200">
                                    Name
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="you@example.com"
                                    />
                                </div>


                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-200">
                                    Email
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="you@example.com"
                                    />
                                </div>


                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-200">
                                    Phone Number
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        placeholder="you@example.com"
                                    />
                                </div>


                                <div className="mt-2">
                                    <button ref={btnRef} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={submitForm}>
                                        Get My Free Report
                                    </button>
                                </div>


                            </div>

                            <div class="blur-xl">
                                <img src="/report_part.png" />
                            </div>

                       </div>

                        <svg
                            viewBox="0 0 1024 1024"
                            className="absolute top-1/2 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
                            aria-hidden="true"
                        >
                            <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                            <defs>
                                <radialGradient
                                    id="759c1415-0410-454c-8f7c-9a820de03641"
                                    cx={0}
                                    cy={0}
                                    r={1}
                                    gradientUnits="userSpaceOnUse"
                                    gradientTransform="translate(512 512) rotate(90) scale(512)"
                                >
                                    <stop stopColor="#7775D6" />
                                    <stop offset={1} stopColor="#E935C1" stopOpacity={0} />
                                </radialGradient>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}