import React, { useState, useEffect } from "react";
import '../App.css';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import GraphCard from '../components/GraphCard';
import { ArrowDownIcon, ArrowUpIcon, HomeIcon } from '@heroicons/react/20/solid'

import { MarkerF } from '@react-google-maps/api'
import {
  withScriptjs,
  useJsApiLoader,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindowF,
  LoadScript
} from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
// const data = require('../data4.json');


var tickFormatter = (value, index) => {
  const limit = 8; // put your maximum character
  if (value.length < limit) return value;
  return `${value.substring(0, limit)}...`;
};


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});


const decimalFormatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  maximumFractionDigits: 0,
});

function CMAReport() {

  const { state } = useLocation();
  const { address, data } = state;

  let closestCities = data.nearby.slice(0,5);

  let rentalHistory = Object.entries(data.market.rentals.priceHistory).map(([k, v]) => { return {date: k, average: v.averageRent, qty: v.totalRentals} });

  const stats = [
    { name: 'Estimate', stat: `${formatter.format(data.estimate.price * 0.9)} - ${formatter.format(data.estimate.price * 1.1) }`, },
    { name: 'Confidence', stat: 'Medium', },
  ]

  const salesStats = [
    { name: 'Average Days On Market', stat: decimalFormatter.format(data.market.sales.avgDaysOnMarket) },
    { name: 'Median Days On Market', stat: data.market.sales.medianDaysOnMarket },
    { name: 'Median Property Value', stat: formatter.format(data.market.sales.medianValue) },
    { name: 'Average Property Value', stat: formatter.format(data.market.sales.averageValue) },
  ]

  const containerStyle = {
    width: '100%',
    height: '300px'
  };

  const center = {
    lat: data.property.latitude,
    lng: data.property.longitude
  };

  const onMarkerLoad = marker => {
    console.log('marker: ', marker)
  }

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  let strings = {
    bedrooms: `${data.property.bedrooms ?? "?"} Bedrooms`,
    bathrooms: `${data.property.bathrooms ?? "?"} Bathrooms`
  }

  return (
    <div className="bg-gray-900 h-100">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-6xl pt-4 pb-4">

          <div className="bg-white px-6 sm:py-8 lg:px-8 rounded-lg p-4">
            <div className="mx-auto max-w-2xl text-center">
              <p className="text-base font-semibold leading-7 text-indigo-600">Property Report For</p>
              <h2 className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-6xl">{ address }</h2>
              <h2 className="mt-2 text-base font-bold tracking-tight text-gray-900">{strings.bedrooms} | {strings.bathrooms} | {data.property.squareFootage} sq. ft.</h2>

              <div>
                <dl className="mt-8 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white  md:grid-cols-2 md:divide-y-0 md:divide-x">
                  {stats.map((item) => (
                    <div key={item.name} className="px-4 py-5 sm:p-6">
                      <dt className="text-base font-normal text-gray-900 text-center">{item.name}</dt>
                      <dd className="mt-1  items-baseline justify-between  text-center">
                        <div className=" items-baseline text-2xl font-semibold text-indigo-600 text-center">
                          {item.stat}
                        </div>
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>


            </div>
          </div>

          <dl className="mt-4 grid grid-cols-1 col-span-2 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-y-0 md:divide-x">
            {salesStats.map((item) => (
              <div key={item.name} className="px-4 py-5 sm:p-6 content-center">
                <dt className="text-base text-center font-normal text-gray-900">{item.name}</dt>
                <dd className="mt-1 text-center content-center">
                  <div className="text-2xl font-semibold text-indigo-600 text-center">
                    {item.stat}
                  </div>
                </dd>
              </div>
            ))}
          </dl>

          <div class="grid sm:grid-cols-2 md:grid-cols-4 gap-4 mt-4">

            <GraphCard title="Nearby City Population" class="sm:col-span-full md:col-span-2">
              <ResponsiveContainer width={'99%'} height={300}>
                <BarChart width={100} height={400} data={closestCities} >
                  <XAxis dataKey="name" tickFormatter={tickFormatter} />
                  <YAxis tickFormatter={(value) =>
                    new Intl.NumberFormat("en-US", {
                      notation: "compact",
                      compactDisplay: "short",
                    }).format(value)
                  } />
                  <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
                  <Bar type="monotone" dataKey="population" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </GraphCard>
            
            <GraphCard title="Location" class="sm:col-span-full md:col-span-2">
              <LoadScript
                googleMapsApiKey="AIzaSyC5-Z7Suz2ljOqMDNWuG8riJz3IqqANT_4"
              >
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  onClick={() => setActiveMarker(null)}
                  zoom={12}
                  options={{
                    disableDefaultUI: true,
                    scaleControl: true,
                    zoomControl: true,
                    zoomControlOptions: {

                    },
                  }}
                >
                  <MarkerF
                    
                    onLoad={() => {}}
                    position={{lat: data.property.latitude, lng: data.property.longitude}}
                  />
                </GoogleMap>
              </LoadScript>
            </GraphCard>

            <GraphCard title={`Average Rental Price in ${data.geo.city}`} subtitle="This is the average rental amount per month in USD." class="col-span-full">
              <ResponsiveContainer width={'99%'} height={300}>
                  <LineChart width={100} height={400} data={rentalHistory} >
                    <XAxis dataKey="date" tickFormatter={tickFormatter} />
                  <YAxis tickFormatter={(value) => formatter.format(value)}/>
                  <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
                    <Line type="monotone" dataKey="average" fill="#8884d8" />
                  </LineChart>
              </ResponsiveContainer>
            </GraphCard>

            <GraphCard title={`Number of Rentals in ${data.geo.city}`} subtitle="This is the number of properties that were listed on rental websites during the given period." class="col-span-full">
                <ResponsiveContainer width={'99%'} height={300}>
                  <BarChart width={100} height={400} data={rentalHistory} >
                    <XAxis dataKey="date" tickFormatter={tickFormatter} />
                    <YAxis />
                    <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
                    <Bar type="monotone" dataKey="qty" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </GraphCard>


              <GraphCard title={`Similar Nearby Properties in ${data.geo.city}`} subtitle="This is a list of the nearby properties that are similar in attributes." class="col-span-full">
                <div className="flow-root">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                Address
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Bedrooms
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Bathrooms
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Type
                              </th>
                              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Price
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {data.similar.map((similar) => (
                              <tr key={similar.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {similar.formattedAddress}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{similar.bedrooms}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{similar.bathrooms}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{similar.propertyType}</td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatter.format(similar.price)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </GraphCard>


          </div>

        </div>
      </div>
    </div>
  );
}

export default CMAReport;
