
export default function GraphCard(props) {
    return(
        <div className={"divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow " + props.class }>
            <div className="px-4 py-5 sm:px-6">
                <dt className="text-base font-normal text-gray-900">{props.title}</dt>
                <dt className="text-sm font-normal text-gray-600">{props.subtitle}</dt>
            </div>
            <div className="px-4 py-5 sm:p-6">
                {props.children}
            </div>
        </div>
    )
}